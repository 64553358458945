.my-modal {
  width: 88% !important;
}
.medium-modal {
  width: 60% !important;
}
.my-medium-modal {
  width: 60% !important;
}
.landing-modal {
  width: 98% !important;
}

.popup-mantra {
  margin-top: 8% !important;
  width: 57vw !important;
}

.popup-info {
  margin-top: 12% !important;
  width: 44vw !important;
}

.view-data {
  width: 92% !important;
  height: 98% !important;
}

.popup-certificate {
  margin-top: 1% !important;
  height: auto;
  width: 63% !important;
}

.address_popup{
  margin-top: 14rem;
  width: auto;
  max-width: fit-content;
}
.popup-r2file{
  width: fit-content !important;
}
