/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./app/company/company-info/modal.css";
/* styles.css */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
/* Your custom styles go here */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* @import 'codemirror/lib/codemirror.css';
@import "~codemirror/lib/codemirror.css";
@import "~codemirror/addon/lint/lint.css";
@import "~codemirror/addon/hint/show-hint.css"; */
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700"); */
@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../src/assets/fonts/MontserratNew/Montserrat-Regular.otf');
}


/* #e30613 */

/* * :not(.angular-editor-toolbar > *){ */
/* *{
  font-family: 'Montserrat', sans-serif !important;
} */

* :not(.editor) {
  font-family: 'Montserrat', sans-serif;
}

:root {
  --red: #e30615cc;
  --blue: #00526f;
  --black: #1d1d1b;
  --navbg: #444444;
}

/* 

.form-control-lg{
    height: 46px;
  }
  .input-group-addon:last-child {
    border-left: 0;
  }
  .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child>.btn-group:not(:first-child)>.btn, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .input-group-addon, .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }
  .input-group .form-control, .input-group-addon, .input-group-btn {
    display: table-cell;
  } */
/* gmi primary button */
.gmi-primary-btn {
  width: max-content;
  background-color: var(--red);
  padding: 10px 20px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  border: 0;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(238, 191, 191, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gmi-secondary-btn {
  width: max-content;
  background-color: #fff;
  border: 1px solid var(--red);
  padding: 10px 20px;
  color: var(--red);
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px 10px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(238, 191, 191, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.gmi-primary-btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: #fff;
  background-color: var(--black);
}

.gmi-primary-btn:focus {
  color: #fff;
}

.gmi-oval-btn {
  padding: 8px 15px;
  font-size: 18px;
  width: 130px;
  color: #fff;
  background-color: var(--red);
  border-radius: 50px;
  margin-right: 10px;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-align: center;
}

.gmi-oval-btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: #fff;
}

.gmi-oval-btn:focus {
  color: #fff;
}

.gmi-primary-btn:disabled {
  background-color: #e3e3e3;
  color: #000;
}

/* gmi logo */
.gmi-logo-img {
  width: auto;
  height: 60px;
}

/* gmi red header text */
.red-header-text {
  color: var(--red) !important;
}

/* navbar bg color */
.nav-color {
  background-color: var(--navbg) !important;
}

.nav-color a {
  color: #fff !important;
}

.nav-color a:hover {
  color: #fff !important;
  border-bottom: 2px solid #fff;
}

.nav-color ul li .active {
  color: #fff !important;
  border-bottom: 2px solid #fff;
}

.navbar-default .navbar-nav>.active>a {
  background-color: transparent;
  color: #fff !important;
  border-bottom: 2px solid #fff;
}

.navbar-default .navbar-nav>.active>a:hover {
  background-color: transparent;
  color: #fff !important;
  border-bottom: 2px solid #fff;
}

.btn-container {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .bs-datepicker-container {
    margin-left: 65px !important;
  }
}

.owl-dt-calendar-view {
  font-size: 20px !important;
}

.angular-editor-toolbar {
  font: 100 1.5rem/15px Roboto, Arial, sans-serif !important;
  background-color: #f5f5f5;
  padding: 0.2rem;
  border: 1px solid #ddd;
}

.swal2-popup {
  width: 42em !important;
}

.swal2-popup .swal2-title {
  font-size: 2.5em !important;
}

.swal2-popup #swal2-content {
  font-size: 15px !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat-Regular', sans-serif;
  font-weight: 400;
  height: 100%;
  background-color: #f1f1f1;
  overflow-x: hidden;
}

.body-white {
  background-color: #fff;
}

.body-white .zsiq_floatmain {
  display: none !important;
}

.star.medium,
.rating.medium .star {
  width: 30px !important;
  height: 30px !important;
  cursor: pointer !important;
}

.rating {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: end !important;
  margin-bottom: 5px;
}

.container-fluid,
.row {
  height: 100%;
}

.ngx-text-highlight {
  background: yellow !important;

  /* font-weight: 800 !important; */
}

lottie-animation-view div {
  margin: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-10 {
  margin-top: 10px;
}

.pt-20 {
  margin-top: 20px;
}

.pt-30 {
  margin-top: 30px;
}

.pt-40 {
  margin-top: 40px;
}

.pad-0 {
  padding: 0;
}

.clr-grey {
  color: rgba(0, 0, 0, 0.5);
}

.form-control:focus {
  border-color: #1d1d1b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #1d1d1b;
}

.search-icon {
  background: url("assets/images/search.png") no-repeat right;
  padding: 0px 20px;
  background-position: 97%;
  background-color: #efeff1;
  width: 100%;
  background-size: contain;
  border: none;
}

.cust-search {
  width: 25%;
  top: 14px;
  right: 10%;
  position: absolute;
}

.subadmin-title {
  padding: 0 0px 10px 30px !important;
  background: #fbfbfb;
}

.subadmin-title h3 {
  font-size: 20px;
}

.glyphicon-bell {
  transform: rotate(22deg);
}

.custom-form-control {
  height: 40px;
  font-size: 12px;
  padding: 0 0 0 9px;
}

.users-table {
  padding: 20px;
  /*background: #f5f8ff;*/
  background: #f5f8ffd1;
  /*background: url("assets/images/pixel.jpg") repeat;*/
}

.custom-btn {
  background: #2b9793 !important;
  border-color: #2b9793 !important;
  color: #fff;
}

.select-hide {
  border: none;
  background: transparent;
  box-shadow: none;
  padding: 0 0px;
  height: 20px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

.drop-filter span {
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 10px;
}

.btn-newuser-clr {
  background-color: #e30613;
  color: #d7dce3;
}

.mat-tab-label,
.mat-tab-link,
.mat-header-cell,
.mat-cell {
  font-family: "Nunito", sans-serif !important;
}

.mat-tab-label-active {
  border-bottom: transparent;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: none !important;
}

.eye-icon {
  background: url("assets/images/eye.png") no-repeat right;
  padding: 0px 20px;
  background-position: 95%;
}

.asterick {
  color: red;
}

a {
  cursor: pointer;
}

.mat-row {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background-color: #e30610c2;
}

*:focus {
  outline: none !important;
}

.mat-calendar-body-selected {
  background-color: #1a546f !important;
  color: #fff !important;
}

.panel-default>.panel-heading {
  color: #1a546f;
  background-color: #fff;
  border-color: #ddd;
  padding: 10px;
  text-align: center;
}

/* mat-dialog-container {
  padding: 0 !important;
} */

.panel-title {
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 24px;
  color: inherit;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 6px;
  border-radius: 10px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  /* background-color: blue; */
  z-index: 1 !important;
}

.mat-accent .mat-slider-thumb {
  height: 20px;
  width: 20px;
  bottom: -12px;
  z-index: 1 !important;
  /*
   background-color: white;
  border: solid 2px white; */
  right: -10px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.buff-bar.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}

.buff-bar.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 6px;
  border-radius: 10px;
  position: absolute !important;
  top: -20px !important;
  z-index: -1 !important;
}

.buff-bar.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.buff-bar.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #d7dce3 !important;
}

.buff-bar.mat-accent .mat-slider-thumb {
  height: 20px;
  width: 20px;
  bottom: -12px;
  display: none !important;
  /*
   background-color: white;
  border: solid 2px white; */
  right: -10px;
}

.buff-bar.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.carousel-inner {
  overflow: auto !important;
}

.ng2-pdf-viewer-container {
  /* height: 500px !important; */
  margin-top: 10px !important;
}

.ngx-editor-textarea {
  height: 150px !important;
}

.ngx-toolbar {
  font-size: 1.9rem !important;
}

.ngx-editor {
  padding: 1% !important;
}

.carousel-indicators {
  display: none !important;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
  background: #fff !important;
}

/* modals are hidden by default */
app-custom-modal {
  display: none;
}

app-custom-modal .custom-modal {
  /* modal container fixed across whole screen */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index must be higher than .jw-modal-background */
  z-index: 1000;
  /* enables scrolling for tall modals */
  overflow: auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

app-custom-modal .custom-modal .custom-modal-body {
  padding: 20px;
  background: #fff;
  padding: 20px;
  background: #fff;
  width: 450px;
  margin: 10% auto;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

app-custom-modal .custom-modal-background {
  /* modal background fixed across whole screen */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* semi-transparent black */
  background-color: rgba(30, 60, 94, 0.2);
  opacity: 0.75;
  /* z-index must be below .jw-modal and above everything else */
  z-index: 500;
}

/* body.custom-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
/* overflow: hidden;
} */

@media only screen and (max-width: 500px) {
  app-custom-modal .custom-modal .custom-modal-body {
    width: 100% !important;
  }
}

.swal2-actions .swal2-cancel {
  margin: 0 !important;
}

.swalCustomClass {
  font-family: "Cabin", sans-serif !important;
}

#driver-highlighted-element-stage {
  background-color: transparent !important;
}

#driver-popover-item .driver-popover-description {
  font-size: 16px !important;
}

/* multiselect dropdown styling */
.selected-list .c-list .c-token {
  font-size: 15px;
  background-color: #e30515cc;
  color: white;
}

.selected-list .c-btn {
  font-size: 15px;
}

.btn-container .c-btn {
  font-size: 15px;
  background-color: #044d6e;
  color: white;
}

.pure-checkbox:hover {
  background-color: #e30515cc;
  color: white;
  transition: background-color 500ms;
}

.pure-checkbox,
label {
  font-weight: normal;
  font-size: 15px;
}

.list-area {
  border: 1px solid black;
}

.mat-datepicker-content-touch {
  display: block !important;
  max-height: auto !important;
  overflow: auto !important;
  margin: 0 !important;
}

.CodeMirror {
  height: auto !important;
}

.package_summary {
  font-style: italic;
  font-size: 15px !important;
  color: #1a546f;
}

.goog-te-combo {
  float: right;
  display: block;
  width: 20%;
  height: 34px;
  padding: 6px 12px;
  font-size: 15px !important;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.custom-class-example .dropdown-list {
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  position: relative !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e30515cc !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fade {
  opacity: 1 !important;
  visibility: visible !important;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.modal.fade .modal-dialog {
  transform: translate(0, 0) !important;
  transition: transform .3s ease-out;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 100% !important;
}

.owl-dt-timer {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  height: 8em !important;
  padding: 0.5em;
  outline: 0;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 24em !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
}

.owl-dt-container-info .owl-dt-container-range {
  outline: 0;
  font-size: medium !important;
}

.owl-dt-container-row:last-child {
  border-bottom: none;
  font-size: small !important;
}

.editor {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  .NgxEditor__MenuBar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .NgxEditor {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
  }

  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
    margin-bottom: 0.7rem;

    pre {
      white-space: pre !important;
    }
  }
}

.editor * {
  font-family: "Times New Roman", Times, serif !important;
}